<template>
  <div class="appointmentModal">
    <h2 class="mt-5 mb-4 text-center blueHeader">
      Medio por el que nos conoce
    </h2>
    <div class="d-flex justify-content-center align-items-center">
      <div class="form-group field-100 text-center">
        <label for="contact_tel">Medio de contacto</label>
        <select
          v-model="contact_type"
          class="form-select w-auto inputsGeneralClass">
          <option selected disabled hidden value="">Tipo</option>
          <option
            v-for="contactType in contactTypes"
            :key="`${contactType.key}-${contactType.name}`"
            :value="contactType.key">
            {{ contactType.name }}
          </option>
        </select>
      </div>
    </div>
    <!-- Footer Actions -->
    <div class="appointmentModal__footer">
      <b-button class="col-5 w-100 btn btn-secondary fs-5" @click="closeModal">
        Cancelar
      </b-button>
      <b-button
        class="col-5 w-100 btn btn-primary fs-5"
        variant="primary"
        @click="saveAdvertising">
        Guardar
      </b-button>
    </div>
    <!-- Footer Actions / -->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import { contactTypes } from "@/constants/appointments";

export default {
  name: "Advertising",
  data() {
    return {
      contactTypes,
      contact_type: "",
    };
  },
  validations: {
    contact_type: {
      required,
    },
  },
  mounted() {
    this.contact_type = this.getPatientSelected.contact_medium;
  },
  computed: {
    ...mapGetters(["getPatientSelected", "getModal"]),
  },
  methods: {
    ...mapActions([
      "updateClinic",
      "updateMedic",
      "updateMedicList",
      "updateAlerts",
    ]),
    closeModal() {
      this.getModal("generalFiliationModal").hide();
      this.$emit("reloadPatientList");
    },
    saveAdvertising() {
      const payload = {
        contact_medium: this.contact_type,
      };
      this.$api.appointments
        .updateContactMedium(payload, this.getPatientSelected.patient_id)
        .then(() => this.closeModal());
    },
  },
};
</script>
